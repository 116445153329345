import { PriceOfferOrderTag } from '../../../../../../services/api/price-offer/PriceOffer.types'
import { booleanAsStringToCzech } from '../../../../../../utils/Helper'
import React from 'react'

export type PriceOfferPdfTableTagsProps = {
    tags: PriceOfferOrderTag[]
}

const PriceOfferPdfTableTags = ({ tags }: PriceOfferPdfTableTagsProps) => (
    <div className='pdf-tag-section'>
        {tags?.map((tag, index) => (
            <div key={index} className='pdf-tag'>
                <span className='pdf-text-tags-bold'>{tag.label}</span>
                <span className='pdf-text-tags'>{booleanAsStringToCzech(tag.value)}</span>
            </div>
        ))}
    </div>
)

export default PriceOfferPdfTableTags
